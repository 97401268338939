"use strict";

export function initInScope ($scope) {
    let $readmore = $scope.find('.js-readmore');

    $readmore.each((index, element) => {
        let $element = $(element);

        let $readmoreAction = $element.find('.js-readmore__button');
        let $readmoreHeight = $element[0].offsetHeight;

        if (!matchMedia('(min-width: 768px)').matches && $readmoreHeight > 250) {
            $readmore.removeClass('is-open');
            $readmoreAction.removeClass('d-none');
        }

        $readmoreAction.on('click', function () {
            if (!$readmore.hasClass('is-open')) {
                $readmore.addClass('is-open');
                $readmoreAction.addClass('d-none');
            }
        });
    });
}
