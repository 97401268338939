"use strict";

import {whenValid} from "@elements/parsley-bootstrap-validation";
import initModulesInScope from "@elements/init-modules-in-scope";
import fetch from '@elements/fetch';
import formDataEntries from 'form-data-entries';

let pendingRequest = null;
let currentStep = 0;

export function initInScope ($scope) {
    $scope.find('.js-wizard__step').each(function () {
        let wizardObj = {
            $container: $(this).closest('.js-wizard'),
            $nav: $(this).closest('.js-wizard').find('.js-wizard__nav'),
            $element: $(this)
        };

        let $step = $(this);
        currentStep = $(this).data('wizard-step');

        $step.on('submit', function (evt) {
            evt.preventDefault();
            let $this = $(this);

            whenValid($this).then((isValid) => {
                if(isValid) {
                    let formDataEntries = getFormDataEntries([$this[0]]);
                    let params = new URLSearchParams(formDataEntries);

                    submitFormStep(wizardObj, params);
                }else{
                    console.error('form has error')
                }
            });
        });

        $step.find('.js-wizard__step__day-select').on('change', function (evt) {
            if ($(evt.target)[0].value > 1) {
                $step.find('.js-wizard__step__ticket-types[data-wizard-one-day="true"]').prop('disabled', true);
                $step.find('.js-wizard__step__ticket-types:not([data-wizard-one-day="true"])').prop('checked', true);
            } else {
                $step.find('.js-wizard__step__ticket-types[data-wizard-one-day="true"]').prop('disabled', false);
            }

            if ($(evt.target)[0].value >= 3) {
                $step.find('.js-wizard__step__day-info').addClass('is-visible');
            } else {
                $step.find('.js-wizard__step__day-info').removeClass('is-visible');
            }
        });

        $step.find('.js-wizard__highlight-input input').each(function () {
            let $input = $(this),
                $container = $(this).closest('.js-wizard__highlight-input');

            if ($input[0].value === '0' || $input[0].value.length === 0) {
                $container.addClass('is-inactive');
            }

            $input.on('change', function (evt) {
                if ($(this)[0].value !== '0') {
                    $container.removeClass('is-inactive');
                } else {
                    $container.addClass('is-inactive');
                }
            });
        });

        $step.find('.js-wizard__back').on('click', function () {
            let prevStep = currentStep -1;
            let formDataEntries = getFormDataEntries([wizardObj.$container.find('form')[0]]);
            formDataEntries.push(['step', prevStep]);
            let params = new URLSearchParams(formDataEntries);


            submitFormStep(wizardObj, params);
        });

        initNav(wizardObj);
    });
}

function initNav(wizardObj) {
    if (wizardObj.$nav.length) {
        wizardObj.$nav.find('.js-wizard__nav__item').off('click');

        wizardObj.$nav.find('.js-wizard__nav__item').on('click', function (evt) {
            evt.preventDefault();

            if ($(this).data('wizard-step') <= currentStep) {
                let formDataEntries = getFormDataEntries([wizardObj.$container.find('form')[0]]);
                formDataEntries.push(['step', $(this).data('wizard-step')]);
                let params = new URLSearchParams(formDataEntries);

                submitFormStep(wizardObj, params);
            }
        });
    }
}
function setNavState(wizardObj) {
    wizardObj.$nav.find('.js-wizard__nav__item').each(function () {
        $(this).removeClass('is-active');
    });

    let previousStep = currentStep-1;

    if (currentStep === 1){
        wizardObj.$nav.find('.js-wizard__nav__item[data-wizard-step='+ 2 +']').removeClass('is-finished');
        wizardObj.$nav.find('.js-wizard__nav__item[data-wizard-step='+ 3 +']').removeClass('is-finished');
        wizardObj.$nav.find('.js-wizard__nav__item[data-wizard-step='+ 4 +']').removeClass('is-finished');
    }else if(currentStep === 2){
        wizardObj.$nav.find('.js-wizard__nav__item[data-wizard-step='+ 3 +']').removeClass('is-finished');
        wizardObj.$nav.find('.js-wizard__nav__item[data-wizard-step='+ 4 +']').removeClass('is-finished');
    }else if(currentStep === 3){
        wizardObj.$nav.find('.js-wizard__nav__item[data-wizard-step='+ 4 +']').removeClass('is-finished');
    }

    wizardObj.$nav.find('.js-wizard__nav__item[data-wizard-step='+ currentStep +']').addClass('is-active');
    wizardObj.$nav.find('.js-wizard__nav__item[data-wizard-step='+ previousStep +']').addClass('is-finished');
    if(previousStep > 1){
        for(let i = previousStep; i >= 0; i = i - 1){
            wizardObj.$nav.find('.js-wizard__nav__item[data-wizard-step='+ i +']').addClass('is-finished');
        }
    }
}

function submitFormStep(wizardObj, data) {
    if(pendingRequest){
        pendingRequest.abort();
        pendingRequest = null;
    }

    pendingRequest = fetch(wizardObj.$container.data('action'), {
        method: 'GET',
        headers: {
            'X-Requested-With' : 'XMLHttpRequest',
            'Content-Type': 'application/json'
        },
        body: data
    });

    let offset = wizardObj.$container.offset().top - 138;
    if (matchMedia('(max-width: 767px)').matches) {
        offset = wizardObj.$container.offset().top - 90
    }

    if ($(window).scrollTop() > offset) {
        try {
            window.scroll({
                top: offset,
                behavior: 'smooth'
            });
        } catch (e) {
            window.scrollTo(0, offset)
        }
    }

    wizardObj.$container.find('.js-wizard__loading').attr('hidden', false);
    wizardObj.$container.addClass('is-loading');

    pendingRequest.then((res) => {
        return res.clone().json()
    }).then((res) => {
        if (res.success) {
            wizardObj.$container.find('.js-wizard__content').html(res.html);
            initModulesInScope(wizardObj.$container);

            currentStep = wizardObj.$container.find('.js-wizard__step').data('wizard-step');
            setNavState(wizardObj);

            let t = setTimeout(function () {
                wizardObj.$container.removeClass('is-loading');
                wizardObj.$container.find('.js-wizard__loading').attr('hidden', true);
                clearTimeout(t)
            }, 300);
        }

        pendingRequest = null;
    }).catch((error) => {
        if (error.name !== 'AbortError') {
            /*Do error stuff*/
            console.error(error);
            pendingRequest = null;
        }
    });
}

function getFormDataEntries(forms) {
    let formDataArray = [];

    forms.map(form => {
        for(var pair of formDataEntries(form)) {
            formDataArray.push(pair);
        }
    });

    return formDataArray;
}