export function setup() {
    window.dataLayer = window['dataLayer'] || [];
}

export function trackEvent(data, {debug}) {
    window.dataLayer.push(data.datalayer);
    if (debug) {
        console.log('GTM Tracking - dataLayer push:', data.datalayer);
    }
}

function exists(x) {
    return x !== null && typeof x !== 'undefined'
}