"use strict";
import loadScript from '@elements/load-script';

export function initInScope ($scope) {
    let $typeahead = $scope.find('.js-typeahead');

    loadScript("/static/js/libs/typeahead.min.js").then(() => {
        let $this = $typeahead;

        let categories = $this.data('typeahead-categories');
        if (!categories || !Array.isArray(categories)) {
            console.warn('typeahead could not initialize, data-typeahead-category is not set or not a valid JSON array');
            return;
        }

        let typeHeadOptionsArray = [{
            hint: true,
            highlight: true,
            minLength: 3
        }];

        let bloodhounds = [];

        /*create the options for each category*/
        $.each(categories, function (index, category) {
            let bloodhound = new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace('text'),
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                remote: {
                    url: $this.data('typeahead-remote-url'),
                    wildcard: '%QUERY',
                    filter: function (response) {
                        if (response && response[category.name] && response[category.name]) {
                            return response[category.name];
                        }
                        return [];
                    }
                }
            });

            bloodhounds.push(bloodhound);

            typeHeadOptionsArray.push({
                name: category.name,
                limit: Number.MAX_VALUE, /*workaround for https://github.com/twitter/typeahead.js/issues/1232*/
                display: 'title',
                templates: {
                    header: '<h3 class="typeahead__title">'+ category.title +'</h3>',
                    suggestion: function (data) {
                        return '<div data-id="'+ data.id +'">'+ data.title +'</div>'
                    }

                },
                source: bloodhound
            });
        });

        let currentSuggestions = $($this.data('prefill'));
        currentSuggestions.map((index, item) => createNewItem($this, item.id, item.title));

        $this.typeahead.apply($this, typeHeadOptionsArray).on('typeahead:selected', (evt, selected) => {
            createNewItem($this, selected.id, selected.title)
        });
    }).catch(console.error);
}

let suggestions = [];
function createNewItem($typeahead, id, title) {
    let $suggestionInput = $($typeahead.data('suggestions')),
        template = '<button type="button" data-id="'+ id +'" class="typeahead-selected-btn">'+ title +'<span class="typeahead-selected-btn__icon icon icon-cross" aria-label="remove" title="remove"></span></button>',
        $suggestionPlaceholder = $typeahead.closest('.js-typeahead-container').find('.js-typeahead-selection');

    suggestions.push(id);
    $suggestionInput.val(suggestions.join(','));
    $typeahead.typeahead('val', '');

    let $newItem = $(template);
    $suggestionPlaceholder.append($newItem);

    $newItem.on('click', function () {
        suggestions = suggestions.filter(e => e !== $(this).data('id').toString());
        $suggestionInput.val(suggestions.join(','));
        $(this).remove();
    });
}