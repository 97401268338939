"use strict";

import initModulesInScope from "@elements/init-modules-in-scope";
import { showNotification } from '@elements/alert-notification';
import * as tracking from './tracking';
import {initGtmFormSentEventTracking} from "./tracking";

export function initInScope($scope) {
    $scope.find('.js-ajax-modal').each(function () {
        let $modal = $(this);
        initModal($modal);
    });

    $scope.find('.js-ajax-modal__open').on('click', function (e) {
        e.preventDefault();
        let $this = $(this);
        let $target = $($this.data('target'));
        // set ajax url from button if defined
        if ($this.data('ajax-modal-url')) {
            $target.data('ajax-url', $this.data('ajax-modal-url'));
        }
        // set ajax params from button if defined
        if ($this.data('ajax-modal-params')) {
            $target.data('ajax-params', $this.data('ajax-modal-params'));
        }
    });
}

function initModal($modal) {
    $modal.find('.js-ajax-modal__loading').attr('hidden', null);
    $modal.find('.js-ajax-modal__content').attr('hidden', 'hidden');

    $modal.on('show.bs.modal', onShowHandler);
}

function onShowHandler() {
    let $modal = $(this);
    let url = $modal.data('ajax-url');

    if ($modal.data('ajax-config-url')
        && _config[$modal.data('ajax-config-url')] ) {
        url = _config[$modal.data('ajax-config-url')];
    }

    if (!url) {
        console.error('Can\'t load content for js-ajax-modal ', this, '. data-ajax-url was not set.');
        // closeModal(this);
        return;
    }

    $modal.find('.js-ajax-modal__loading').attr('hidden', null);
    $modal.find('.js-ajax-modal__content').attr('hidden', 'hidden');

    $.ajax(url, {
        dataType: 'json',
        data: $modal.data('ajax-params'),
        cache: false
    }).done(function (result) {
        if(result.success === true) {
            $modal.removeClass('is-loading');
            $modal.find('.js-ajax-modal__loading').attr('hidden', 'hidden');

            let $result = $('<div>').html(result.html);
            let $content = $modal.find('.js-ajax-modal__content');
            $content.empty()
                .append($result)
                .attr('hidden', null);

            initModulesInScope($content);
            // tracking.initGtmEventTracking({});

            // $modal.triggerHandler('ajaxModal.loaded');
        } else {
            // $modal.triggerHandler('ajaxModal.failed');
            $modal.removeClass('is-loading');
        }
    }).fail(function (jqXHR, textStatus, errorThrown) {
        console.error(textStatus, errorThrown, jqXHR);
        showNotification({
            type: 'danger',
            title: 'Error: '+ errorThrown,
            content: 'Status:' + jqXHR.status,
            closable: true
        });
        $modal.removeClass('is-loading');
    });
}
