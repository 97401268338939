"use strict";
import initModulesInScope from "@elements/init-modules-in-scope";

export function init() {
    let $tabs = $('.js-login-tabs'),
        $tabItem = $tabs.find('a.nav-link');

    $tabItem.on('shown.bs.tab', function (e) {
        initModulesInScope($('body'));
    });
}