"use strict";

export function initInScope ($scope) {
    let $slider = $scope.find('.js-hero-slider');

    import('slick-carousel').then(function () {
        $slider.each(function () {
            let $container = $(this);
            let $mainSlider = $container.find('.js-hero-slider__slider');

            $mainSlider.slick({
                dots: true, /*should always be true; hide with css (accessibility)*/
                slidesToShow: 1,
                swipeToSlide: true,
                infinite: false,
                slidesToScroll: 1,
                fade: true,
                appendDots: $container.find('.js-hero-slider__dots'),
                autoplay: true,
                autoplaySpeed: 5000
            });
        });
    });
}