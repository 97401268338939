import * as gtm from './tracking/gtm';
import $ from "jquery";

export const initGtmEventTracking = createEventTracking(gtm);

function createEventTracking({setup,trackEvent}) {
    return ({dataAttributePrefix = 'event-tracking', debug = false} = {}) => {
        setup({debug});

        $(`[data-${dataAttributePrefix}]`).each(function (index, element){
            let dataEventTracking = $(element).data(`${dataAttributePrefix}`);

            if (dataEventTracking.type === 'click'){
                // For click events
                $(element).on('click', function () {
                    if (dataEventTracking.responseTracking === true){
                        let request = fetch(dataEventTracking.responseTrackingUrl, { method: 'get' });
                        responseTracking(request);
                    }else{
                        let data = $(this).data(`${dataAttributePrefix}`);
                        trackEvent(data, {debug});
                    }
                });
            }else if(dataEventTracking.type === 'ajax-form'){
                // For ajax-form sent
                $(element).on('success.ajax-form', function(evt, result) {
                    if (dataEventTracking.responseTracking === true){
                        if (result){
                            resultTracking(result);
                        }else{
                            console.error('No Result');
                        }
                    }else{
                        let data = $(this).data(`${dataAttributePrefix}`);
                        trackEvent(data, {debug});
                    }
                });
            }
        });
    }
}

export function responseTracking(request) {
    // Unpack json response body if the promise was created via fetch
    let promise = request.then(response => (response
        && response.json
        && typeof response.json === 'function'
        && response.clone
        && typeof response.clone === 'function')
        ? response.clone().json()
        : response
    );

    promise.then(result => {
        if (result['__trackingScript']) {
            try {
                eval(result['__trackingScript']);
            } catch (e) {
                console.error(e)
            }
        }
    });
}

export function resultTracking(result) {
    if (result.__trackingScript) {
        try {
            eval(result.__trackingScript);
        } catch (e) {
            console.error(e)
        }
    }
}
