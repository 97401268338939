"use strict";

export function initInScope ($scope) {

    if (matchMedia('(min-width: 768px)').matches) {
        return;
    }

    let $navItems = $scope.find('.js-touch-nav__item'),
        $navItemsLinks = $scope.find('.js-touch-nav__item__toggle');

    $navItemsLinks.on('click', function (evt) {
        evt.preventDefault();
        evt.stopImmediatePropagation();

        let $item = $(this).closest('.js-touch-nav__item');

        if (!$item.hasClass('is-open')) {
            $item.addClass('is-open');
        } else {
            $item.removeClass('is-open');
            $item.removeClass('is-initial-open');
        }
    });
    $scope.find('.js-touch-nav__item__toggle--level').on('click', function () {
        let level = $(this).index();

        $navItems.filter('.is-open').eq(level).find('.is-open').removeClass('is-open');
        $navItems.filter('.is-open').eq(level).removeClass('is-open');

        $navItems.filter('.is-initial-open').eq(level).find('.is-initial-open').removeClass('is-initial-open');
        $navItems.filter('.is-initial-open').eq(level).removeClass('is-initial-open');
    });
}