"use strict";

export function init() {
    $('.js-nav__toggle').on('click', function (evt) {
       evt.preventDefault();

       let $mainNav = $(this).closest('.js-nav');

       if(!$mainNav.hasClass('is-open')) {
           $mainNav.addClass('is-open');
       } else {
           $mainNav.removeClass('is-open');

           $mainNav.find('.js-touch-nav__item').removeClass('is-open');
       }
    });
}