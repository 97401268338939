"use strict";

export function initInScope ($scope) {
    $scope.find('.js-toggle-notification').each(function(){
        const notification = $(this);
        const button = notification.find('.js-toggle-notification__button');
        const icon = notification.find('.js-toggle-notification__icon');
        const isHidden = sessionStorage.getItem(notification.attr('data-session'));
        updateState(notification, button, icon, isHidden);
        button.on({
            click:function(){
                if (sessionStorage.getItem(notification.attr('data-session')) === 'true'){
                    sessionStorage.removeItem(notification.attr('data-session'));
                    updateState(notification, button, icon, 'false');
                } else {
                    sessionStorage.setItem(notification.attr('data-session'), 'true');
                    updateState(notification, button, icon, 'true');
                }
                return false;
            }
        });
    });
}

function updateState(notification, button, icon, isHidden){
    let icons = {
        open:'profile',
        close:'close'
    }
    let labels = {
        open:'open',
        close:'close'
    }
    if (typeof button.html() !== 'undefined'){
        if (typeof button.attr('data-icon') !== 'undefined'){
            const buttonIcon = button.attr('data-icon').split('/');
            icons.open = buttonIcon[0];
            icons.close = buttonIcon[1];
        }
        if (typeof button.attr('data-label') !== 'undefined'){
            const buttonLabel = button.attr('data-label').split('/');
            labels.open = buttonLabel[0];
            labels.close = buttonLabel[1];
        }
    }
    if (isHidden === 'true'){
        notification.addClass('is-hidden');
        if (typeof button.html() !== 'undefined'){
            button.attr('aria-label', labels.open);
        }
        if (typeof icon.html() !== 'undefined'){
            icon.removeClass('icon-'+icons.close);
            icon.addClass('icon-'+icons.open);
        }
    } else {
        notification.removeClass('is-hidden');
        if (typeof button.html() !== 'undefined'){
            button.attr('aria-label', labels.close);
        }
        if (typeof icon.html() !== 'undefined'){
            icon.addClass('icon-'+icons.close);
            icon.removeClass('icon-'+icons.open);
        }
    }
}