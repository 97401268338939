"use strict";

export function initInScope ($scope) {
    let modal = $scope.find('.js-ticket-modal');
    let buttons = $('.js-ticket-modal__button');
    let links = $('.js-ticket-modal__link');

    
    buttons.on('click', function (){
        let id = $(this).attr('data-ticket-id');

        links.each(function() {
            let href = $(this).attr('href');
            href = href.replace(/[?&]ticketCount\[\d+\]=\d+/g, '');
            let url;

            if(href.indexOf('?') >= 0) {
                url = href + `&ticketCount[${id}]=1`;
            }else{
                url = href + `?ticketCount[${id}]=1`;
            }

            $(this).attr('href', url);
        })
    });

    modal.on('hide.bs.modal', function(){
        links.each(function() {
            let href = $(this).attr('href');
            let url = href.replace(/[?&]ticketCount\[\d+\]=\d+/g, '');
            $(this).attr('href', url);
        })
    });
}