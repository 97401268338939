"use strict";
import fetch from '@elements/fetch';
export function initInScope ($scope) {

    let $chart = $scope.find($('.js-chart')),
        chartContext = $chart[0].getContext('2d');

    let pendingRequest = fetch( $chart.data('chart-url'));

    pendingRequest.then((res) => {
        return res.clone().json()
    }).then((res) => {
        if (res.success) {
            let labels = [],
                values = [],
                backgrounds = [];

            for (let i = 0; i < res.data.length; i++) {
                labels.push(res.data[i].label);
                values.push(res.data[i].value);
                backgrounds.push(createGradient(chartContext, res.data[i].background.start, res.data[i].background.stop));
            }

            import('chart.js').then(function (){
                Chart.platform.disableCSSInjection = true;

                import('chartjs-plugin-labels').then(function () {
                    new Chart($chart, {
                        type: 'doughnut',
                        data: {
                            labels: labels,
                            datasets: [{
                                label: '',
                                data: values,
                                backgroundColor: backgrounds,
                                borderColor: ['#192b4f','#192b4f','#192b4f','#192b4f'],
                                borderWidth: 2
                            }]
                        },
                        options: {
                            plugins: {
                                labels: {
                                    render: 'percentage',
                                    showZero: true,
                                    fontSize: 12,
                                    fontColor: '#fff',
                                    showActualPercentages: true
                                }
                            },
                            tooltips: {
                                enabled: false,
                            },
                            legend: {
                                display: false
                            }
                        }
                    });
                });
            });
        }
    }).catch((error) => {
        if (error.name !== 'AbortError') {
            /*Do error stuff*/
            console.error(error);
        }
    });
}

function createGradient($chart, start, stop) {
    let gradient = $chart.createLinearGradient(0, 10, 0, 200);
    gradient.addColorStop(0, start);
    gradient.addColorStop(1, stop);

    return gradient;
}

