"use strict";
import {responseTracking, resultTracking} from "./tracking";

export function initInScope($scope) {
    trackViewItemList($scope);
}

function trackViewItemList($scope){
    let $ajaxForm = $scope.find('.js-ajax-form');

    $ajaxForm.on('success.ajax-form', function(evt, result) {
        resultTracking(result)
    });
}
