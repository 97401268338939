"use strict";


export function initInScope ($scope) {

    $scope.find('.js-price__single-price').each( function(){
        let singlePrice = parseFloat($(this).data('single-price')).toFixed(2);
        let parent = $(this).closest('.js-price');
        let amount = parent.find('.js-price__amount');
        let totalPrice = parent.find('.js-price__total-price');
        let total = 0;

        amount.on('change', function (){
            total = parseFloat(parseFloat(amount[0].value).toFixed(2) * singlePrice).toFixed(2);
            totalPrice.text(total);
        });
    });
}