"use strict";

export function initInScope ($scope) {
    let $parents = $scope.find('.js-async-youtube');

    if (!$parents.length) {
        return;
    }

    $parents.each(function () {
        let $iframe = $(this).find('.js-async-youtube__iframe');
        let $play = $(this).find('.js-async-youtube__play');
        let $poster = $(this).find('.js-async-youtube__poster');

        $play.on('click', function (evt) {
            let src = $play.attr('data-src');

            $iframe.attr('src', src);
            $iframe.attr('hidden', false);
            $poster.attr('hidden', true);
            $play.attr('hidden', true);
        });
    });

}