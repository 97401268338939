"use strict";

export function initInScope($scope) {
    $scope.find('.js-number-spinner-group').on('change input', function (group, i) {
        let $group = $(this);
        let totalMax = +$group.data('number-spinner-group-max');
        let $items = $group.find('.js-number-spinner-group__item');
        let currentTotal = $items.toArray().reduce((total, input) => {
            return total + (+getNumberSpinnerValue($(input)));
        }, 0);
        let remaining = totalMax - currentTotal;

        $items.each((index, element) => {
            let $element = $(element);
            setNumberSpinnerMaxValue($element, (+getNumberSpinnerValue($element)) + remaining);
        })
    });

    function getNumberSpinnerValue($spinner) {
        if (!$spinner.is('.js-number-spinner')) {
            return;
        }
        return $spinner.find('.js-number-spinner__input').val();
    }

    function setNumberSpinnerMaxValue($spinner, maxValue) {
        if (!$spinner.is('.js-number-spinner')) {
            console.error('Can\'t set count of element', $spinner, 'Element is not a number spinner ".js-number-spinner');
            return;
        }

        if(maxValue > 0){
            $spinner.find('.js-number-spinner__input').attr('max', maxValue);
            enableDisableButtons($spinner);
        }
    }

    function enableDisableButtons($spinner) {
        if (!$spinner.is('.js-number-spinner')) {
            console.error('Can\'t en-/disable buttons for element', $spinner, 'Element is not a number spinner ".js-number-spinner"');
            return;
        }

        let $input = $spinner.find('.js-number-spinner__input');
        let $btUp = $spinner.find('.js-number-spinner__up');
        let $btDown = $spinner.find('.js-number-spinner__down');
        let $btnDisable = $spinner.closest('.ticket').find($($spinner.data('button')));
        let inputVal = +$input.val();

        if ($input.attr('max')) {
            if(+$input.attr('max') == inputVal) {
                $btUp.attr('disabled', 'disabled');
            } else {
                $btUp.attr('disabled', null);
            }
        }

        if ($input.attr('min')) {
            if(+$input.attr('min') == inputVal) {
                $btDown.attr('disabled', 'disabled');
                $btnDisable.attr('disabled', 'disabled');

            } else {
                $btDown.attr('disabled', null);
                $btnDisable.attr('disabled', null);

            }
        }
    }
}

