import $ from 'jquery';
import {getConfigValue, requireConfigValues} from '@elements/config-utils';
import initModulesInScope from '@elements/init-modules-in-scope';
import {showNotification, clearAll} from '@elements/alert-notification';
import 'url-search-params-polyfill'; // Edge Polyfill
import 'whatwg-fetch'; // IE10 Polyfill
import fetch from '@elements/fetch';
import formDataEntries from 'form-data-entries';
import {translate} from '@elements/translations';
import {responseTracking} from "@elements/tracking";

const configName = '_cartConfig';

let pendingRequest,
    $listLoading,
    $listResult,
    $count;

export function init(){
    $listLoading = $('.js-cart__list-loading');
    $listResult = $('.js-cart__list-result');
    $count = $('.js-cart__count');

    // initial cart list & cart list count
    if (($listResult && $listResult.length) || ($count && $count.length)) {
        getCartInfo();
    }
}

export function initInScope($scope, {onAdd, onAddSucceed, onAddFailed} = {}) {
    let $cartForm = $scope.find('.js-cart__form');
    let $cartRemove = $scope.find('.js-cart__remove');
    let $cartNext = $scope.find('.js-cart__next');
    let $cartLoading = $('.js-cart__loading');
    let $cartResult = $('.js-cart__result');
    let $cartNotifications = $('.js-cart__notifications');

    if ($cartForm && $cartForm.length) {

        let formTrigger = $cartForm.data('trigger') || 'submit';
        let emptyCart = $cartForm.data('empty-cart');

        requireConfigValues(['cartUrl'], configName);

        $cartForm.each(function () {
            let $this = $(this);
            $this.on(formTrigger, function (evt) {
                evt.preventDefault();
                let $thisForm = $(this);

                $listLoading.attr('hidden', null);
                $listResult.attr('hidden', 'hidden');
                $cartLoading.attr('hidden', null);

                if(emptyCart){
                    $cartResult.attr('hidden', 'hidden');
                }

                clearAll({
                    $container: $cartNotifications
                });

                let formDataEntries = getFormDataEntries([$thisForm[0]]);
                let params = new URLSearchParams(formDataEntries);

                let pendingRequest = fetch( getConfigValue('cartUrl', configName) , {
                    body: params
                });

                call(onAdd);

                responseTracking(pendingRequest);

                pendingRequest.then((result) => {
                    return result.clone().json()
                }).then((result) => {
                    if (result.success) {
                        let content = result.content || result.html;
                        if (content) {
                            $cartResult.empty().append(result.content);
                            $cartLoading.attr('hidden', 'hidden');
                            $cartResult.attr('hidden', null);
                            initModulesInScope($cartResult);
                        }

                        setListContent($listResult, $listLoading, result.listContent);

                        if (typeof result.count !== "undefined") {
                            setCount($count, result.count);
                        }

                        $('.js-cart__show-on-submit').prop('hidden', false);

                        call(onAddSucceed);
                    }else{
                        call(onAddFailed);
                    }
                }).catch((error) => {
                    $cartLoading.attr('hidden', 'hidden');

                    if (error.name !== 'AbortError') {
                        /*Do error stuff*/
                        console.error(error);
                    }
                });

                showNotification(pendingRequest, {
                    $container: $cartNotifications
                });

            });

            $this.find('.js-cart__amount').on('change', function () {
                let count = 0;
                for (let i = 0; i < $this.find('.js-cart__amount').length; i++) {
                    count = count + parseInt($this.find('.js-cart__amount')[i].value)
                }

                let $info = $this.find('.js-cart__amount--info');
                let plural = $info.data('translate-plural') || translate('shop.tickets');
                let singular = $info.data('translate-singular') || translate('shop.tickets');

                if (count > 1) {
                    $info.text(count + ' ' + plural);
                } else {
                    $info.text(count + ' ' + singular);
                }

                if (count > 0) {
                    $this.find('.js-cart__submit').prop('disabled', false);
                } else {
                    $this.find('.js-cart__submit').prop('disabled', true);
                }

            });
        });

        // remove product from cart
        $cartRemove.on('click', function (evt) {
            evt.preventDefault();
            $(this).closest('form').find('.js-cart__amount').val('0');
            $(this).closest('form').trigger('change');
        });
    }


    $cartNext.on('click', function (evt) {
        if ($(this).data('target')) {
            $($(this).data('target')).submit();
        }
    })
}


function getFormDataEntries(forms) {
    let formDataArray = [];

    forms.map(form => {
        for(var pair of formDataEntries(form)) {
            formDataArray.push(pair);
        }
    });

    return formDataArray;
}


function setCount($element, count) {
    if (count) {
        $element.removeAttr('hidden');
        $element.text(count);
    } else {
        $element.attr('hidden', 'hidden');
    }
}

function setListContent($listResult, $listLoading, content) {
    if (content) {
        $listResult.empty().append(content);
        $listLoading.attr('hidden', 'hidden');
        $listResult.attr('hidden', null);
        initModulesInScope($listResult);
    } else {
        $listLoading.attr('hidden', 'hidden');
        $listResult.attr('hidden', 'hidden');
    }
}

export function getCartInfo() {
    requireConfigValues(['infoUrl'], configName);

    let request = fetch(getConfigValue('infoUrl', configName), {
        method: 'get',
    });

    let $loadingBtn = $('.js-cart__loading-btn');

    if ( $loadingBtn.length){
        $loadingBtn.attr('hidden', null);

        $loadingBtn.closest('.js-cart__next').addClass('is-loading');
    }


    request.then(response => response.json())
        .then(result => {
            if (result.success) {
                // setListContent($listResult, $listLoading, result.listContent);

                if (typeof result.count !== "undefined") {
                    setCount($count, result.count);
                }

                if (result.isValid) {
                    $('.js-cart__next').removeClass('disabled');
                } else {
                    $('.js-cart__next').addClass('disabled');
                }

                if ( $loadingBtn.length){
                    $loadingBtn.attr('hidden', 'hidden');
                    $loadingBtn.closest('.js-cart__next').removeClass('is-loading');
                }
            }
        }).catch(e => {
        console.warn(e);
        // $listLoading.attr('hidden', 'hidden');
        // $listResult.attr('hidden', 'hidden');
    });
}

// Call the given function if it really is one
function call(fnc, ...params) {
    if (fnc && typeof fnc === 'function') {
        fnc(...params);
    }
}