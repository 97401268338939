"use strict";

export function initInScope($scope) {
    let $notification = $scope.find('.js-flexview-cookies');

    if ($notification) {
        if (!Cookiebot.consent['marketing'] || !Cookiebot.consent['statistics'] || !Cookiebot.consent['preferences']) {
            $notification.removeClass('d-none').attr('aria-hidden', 'false');
            let $button = $notification.find('.js-flexview-cookies__btn');
            if ($button) {
                $button.on('click', function () {
                    Cookiebot.submitCustomConsent(true, true, true);
                    location.reload();
                });
            }
        }
    }
}