"use strict";

export function initInScope ($scope) {
    let $select = $scope.find('.js-select-collapse');

    $select.on('change', function () {
        let $this = $(this),
            $target = $($this.find('option:selected').data('target'));

        $this.closest('.js-select-collapse-group').find('input').each(function () {
            $(this).prop('required', false);
        });

        if ($target) {
            $target.collapse('show');
            $target.find('input:not(.tt-hint)').prop('required', true);
        }
    });

}