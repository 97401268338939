"use strict";

import loadScript from '@elements/load-script';
import {getConfigValue} from '@elements/config-utils';

export function initInScope ($scope) {
    let recaptchas = $scope[0].querySelectorAll('.js-recaptcha');
    loadScript('https://www.google.com/recaptcha/api.js?render='+ getConfigValue('recaptchaSitekey')).then(() => {
        grecaptcha.ready(function() {
            recaptchas.forEach(recaptcha => {
                let recaptchaAction =  recaptcha.getAttribute('data-recaptcha-action');
                // do request for recaptcha token
                // response is promise with passed token
                grecaptcha.execute(getConfigValue('recaptchaSitekey'), {action:recaptchaAction})
                    .then(function(token) {
                        // add token value to form
                        recaptcha.querySelector('.js-recaptcha__response').value = token;
                    });
            });
        });
    }).catch(console.error);
}
