"use strict";

//import {setServicePermission} from '@elements/cookie-permissions';

export function initInScope ($scope) {
    $scope.find('.js-allow-targeting').each(function () {
        let $button = $(this);

        $button.on('click', function (evt) {
            evt.preventDefault();

           // setServicePermission('pimcoreTargeting', true);
            if(Cookiebot){
                Cookiebot.submitCustomConsent(Cookiebot.consent['perferences'], Cookiebot.consent['statistics'], true);
            }
            window.location.href = $button.attr('href');
        });
    });
}