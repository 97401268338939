"use strict";

export function initInScope ($scope) {
    $(".js-skip-links__link").on("click", function() {
        let $target = $(this).attr("data-href");
        $("html, body").animate({
            scrollTop: $target.offset().top
        }, 500);
        $target.focus();
    })
}