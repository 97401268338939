"use strict";

export function initInScope ($scope) {

    var $nav = $scope.find('.js-scroll-spy');

    var $links = $nav.find('a');

    $links.each( function(idx, item){
        if ((!(item.getAttribute('href').startsWith('http') || item.getAttribute('href').startsWith('//'))) && item.getAttribute('href').includes('#')) {
            var t = item.getAttribute('href').split('#')
            var targetId = t[1] || t[0];

            $(item).attr('href','#'+ targetId)

        }
    });

    $('body').scrollspy({target: '.js-scroll-spy', offset: 400})


}
