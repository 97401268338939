"use strict";

export function initInScope($scope) {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        console.log("enumerateDevices() not supported.");
        return;
    }

    const constraints = {
        video: true,
    };

    $scope.find('.js-take-photo').each(function (index, container) {
        let $container = $(container),
            $input = $container.find('.js-take-photo__input'),
            $canvas = $container.find('.js-take-photo__context')[0],
            context = $canvas.getContext('2d');

        let player = $container.find('.js-take-photo__stream')[0];

        $container.find('.js-take-photo__toggle').on('click', function () {
            navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
                if ($container.hasClass('is-open')) {
                    $container.removeClass('is-open');
                    player.srcObject.getVideoTracks().forEach(track => track.stop());
                } else {
                    $container.addClass('is-open');
                    player.srcObject = stream;
                }
            });
        });

        //make image
        $container.find('.js-take-photo__capture').on('click', function () {
            context.drawImage(player, 0, 0, $canvas.width, $canvas.height);

            $container.addClass('has-photo');
        });

        //try again
        $container.find('.js-take-photo__retry').on('click', function () {
            $container.removeClass('has-photo');
        });

        //select image
        $container.find('.js-take-photo__submit').on('click', function () {
            $container.removeClass('is-open');
            $container.addClass('is-selected');
            player.srcObject.getVideoTracks().forEach(track => track.stop());

            let imageData = $canvas.toDataURL('image/png')
            $input.val(imageData);
            $input.prop('required', true);

            $container.find('.js-take-photo__upload').prop('required', false);
            $container.find('.js-take-photo__preview').attr('src', imageData).attr('hidden', false).trigger('change');
            $container.closest('form').trigger('change');
        });

        //remove selected image
        $container.find('.js-take-photo__clear').on('click', function () {
            $container.removeClass('is-selected');
            $input.val('');
            $input.prop('required', false);
            $container.find('.js-take-photo__upload').prop('required', true);
            $container.closest('form').trigger('change');
        })
    });
}