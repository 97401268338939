"use strict";

import {getDate} from '@elements/datepicker';
import {dateToISOString, ISOStringToDate, localDateToUTCDate, UTCDateToLocalDate} from "@elements/date-utils";

export function initInScope ($scope) {
    if (typeof window._config.availableDates !== 'undefined'){
        let $availableDates = JSON.parse(window._config.availableDates)[0];
        let $availableStays = JSON.parse(window._config.availableStays);

        $scope.find('.js-datepicker-range--box').each(function () {
            let $rangeBox = $(this),
                $datepickerFrom = $rangeBox.find('.js-datepicker-range__from'),
                $datepickerFromInput = $rangeBox.find('.js-datepicker-range__from .js-datepicker__input'),
                $datepickerTo = $rangeBox.find('.js-datepicker-range__to'),
                $datepickerToInput = $rangeBox.find('.js-datepicker-range__to .js-datepicker__input');

            setValues($rangeBox.find('.js-datepicker-box').eq(0), $datepickerFrom);
            setValues($rangeBox.find('.js-datepicker-box').eq(1), $datepickerTo);

            $rangeBox.on({
                click:function(){
                    $datepickerFromInput.datepicker('option', 'beforeShowDay', function(date){
                        date = localDateToUTCDate(date);
                        if ($availableDates.length > 0){
                            for (let i = 0; i < $availableDates.length; i++){
                                if (dateToISOString(date) === $availableDates[i]){
                                    return [true, ''];
                                }
                            }
                            return [false, ''];
                        }
                        return [false, ''];
                    });
                    $datepickerFromInput.datepicker('setDate', new Date($datepickerFrom.find('.js-datepicker__alt-field').val()));
                    $datepickerFromInput.datepicker('option', 'minDate', new Date($availableDates[0]));
                    $datepickerFromInput.datepicker('option', 'onSelect', function(dateText, inst){
                        let $dateFrom = new Date(inst.selectedYear, inst.selectedMonth, inst.selectedDay);

                        // return [false, ''];
                        setTimeout(function(){
                            inst.dpDiv.find('td').removeClass('highlight');
                            selectRange($rangeBox, inst, $dateFrom, $dateFrom, false);
                            if (typeof window._config.availableStays !== 'undefined'){

                                $.each($availableStays, function(indexRule, rule){

                                    const ruleFromDate = new Date(rule.from);
                                    const ruleToDate = new Date(rule.to);

                                    if ($dateFrom >= ruleFromDate && $dateFrom <= ruleToDate && (rule.weekdays.length === 0 || (rule.weekdays.length > 0 ))){

                                        $datepickerToInput.datepicker('option', 'beforeShowDay', function(date, e){

                                            const $foundRule = $availableStays.filter(rule => {
                                                const ruleFromDate = new Date(rule.from);
                                                const ruleToDate = new Date(rule.to);
                                                return $dateFrom >= ruleFromDate && $dateFrom <= ruleToDate && (rule.weekdays.length === 0 || (rule.weekdays.length > 0))
                                            });

                                            if ($foundRule.length > 0) {
                                                const rule = $foundRule[0];

                                                if (!rule.exact){
                                                    if (rule.dates.length > 0){
                                                        date = localDateToUTCDate(date);

                                                        for (let i = 0; i < rule.dates.length; i++){
                                                            if (dateToISOString(date) === rule.dates[i]){
                                                                if (date < $dateFrom.addDays(rule.duration)){
                                                                    return [true, 'ui-datepicker-unselectable ui-state-disabled'];
                                                                }
                                                                if (rule.exact){
                                                                    if (date > $dateFrom.addDays(rule.duration)){
                                                                        return [true, 'ui-datepicker-unselectable ui-state-disabled'];
                                                                    }
                                                                }
                                                                return [true, ''];
                                                            }
                                                        }
                                                        return [false, ''];
                                                    }
                                                }else{
                                                    if (date < $dateFrom.addDays(rule.duration)){
                                                        return [true, 'ui-datepicker-unselectable ui-state-disabled'];
                                                    }
                                                    if (rule.exact){
                                                        if (date > $dateFrom.addDays(rule.duration)){
                                                            return [true, 'ui-datepicker-unselectable ui-state-disabled'];
                                                        }
                                                    }
                                                }

                                                return [true, ''];
                                            }


                                        });
                                    }
                                });
                            }
                            $datepickerToInput.datepicker('setDate', new Date($datepickerFrom.find('.js-datepicker__alt-field').val()));
                            $datepickerToInput.datepicker('option', 'minDate', $dateFrom);
                            $datepickerToInput.datepicker('option', 'onChangeMonthYear', function(year, month, inst){
                                setTimeout(function(){
                                    highlightRange(inst, []);
                                }, 300);
                            });
                            $datepickerToInput.datepicker('option', 'onSelect', function(dateText, inst){
                                let $dateTo = new Date(inst.selectedYear, inst.selectedMonth, inst.selectedDay);
                                setTimeout(function(){
                                    selectRange($rangeBox, inst, $dateFrom, $dateTo, true);
                                }, 300);
                            });
                            $datepickerToInput.datepicker('show');
                            highlightRange(inst, $availableStays);
                        }, 300);
                    });
                    $datepickerFromInput.datepicker('show');
                }
            });
        });
    } else {
        $scope.find('.js-datepicker-box').each(function () {
            let $this = $(this),
                $datepicker = $this.find('.js-datepicker');

            $this.on('click', function () {
                $datepicker.datepicker('show');
            });

            $this.closest('form').on('change', function () {
                let t = setTimeout(function () {
                    setValues($this, $datepicker);

                    clearTimeout(t);
                }, 1);
            });

            setValues($this, $datepicker);
        });
    }
}

function highlightRange(inst){
    let $dpDiv = inst.dpDiv,
        $currentDayDate = new Date(inst.currentYear, inst.currentMonth, inst.currentDay);

    $dpDiv.find('td').on({
        mouseover:function(){
            let $hoveredDay = $(this),
                $hoveredDayDate = new Date($hoveredDay.attr('data-year'), $hoveredDay.attr('data-month'), $hoveredDay.text());

            $dpDiv.find('[data-month][data-year]').each(function(){
                let $day = $(this),
                    $dayDate = new Date($day.attr('data-year'), $day.attr('data-month'), $day.text());

                if ($dayDate.getTime() > $currentDayDate.getTime() && $dayDate.getTime() < $hoveredDayDate.getTime()){
                    $day.addClass('highlight');
                }
            });
        },
        mouseleave:function(){
            $dpDiv.find('[data-month][data-year]').removeClass('highlight');
        }
    });
}


function selectRange(rangeBox, inst, dateFrom, dateTo, done){
    let $dpDiv = inst.dpDiv,
        $boxFrom = rangeBox.find('.js-datepicker-box').eq(0),
        $datepickerFrom = $boxFrom.find('.js-datepicker'),
        $boxTo = rangeBox.find('.js-datepicker-box').eq(1),
        $datepickerTo = $boxTo.find('.js-datepicker');

    $datepickerFrom.find('.js-datepicker__alt-field').val(dateToISOString(localDateToUTCDate(dateFrom)));
    $datepickerTo.find('.js-datepicker__alt-field').val(dateToISOString(localDateToUTCDate(dateTo)));

    setValues($boxFrom, $datepickerFrom);
    setValues($boxTo, $datepickerTo);

    if (done){
        $dpDiv.find('td').removeClass('highlight');
    }
}

function setValues($this, $datepicker) {
    const date = getDate($datepicker);
    const fullMonth = new Intl.DateTimeFormat($('html').attr('lang'), {month:'long'}).format(date);
    $this.find('.js-datepicker-box__day').html(getDate($datepicker).getDate());
    $this.find('.js-datepicker-box__date').html(fullMonth + ' ' + getDate($datepicker).getFullYear());
}

Date.prototype.addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};