"use strict";

export function initInScope ($scope) {
    let $infoDropdown = $scope.find('.js-info-dropdown');

    if (!sessionStorage.getItem('info-dropdown-dismissed')) {
        $infoDropdown.addClass('is-active');
        $('.js-info-dropdown--toggle[data-target="#'+ $infoDropdown.prop('id') +'"]').addClass('is-active');
    }

    $infoDropdown.find('.js-info-dropdown__close').on('click', function () {
        sessionStorage.setItem('info-dropdown-dismissed', true);

        $infoDropdown.removeClass('is-active');
        $('.js-info-dropdown--toggle[data-target="#'+ $infoDropdown.prop('id') +'"]').removeClass('is-active');
    });

    $scope.find('.js-info-dropdown--toggle').on('click', function (evt) {
        evt.preventDefault();
        let $target = $($(this).data('target'));

        if ($target.hasClass('is-active')) {
            $(this).removeClass('is-active');
            $target.removeClass('is-active');
            sessionStorage.setItem('info-dropdown-dismissed', true);
        } else {
            $(this).addClass('is-active');
            $target.addClass('is-active');
        }
    });
}