"use strict";

import {getCartInfo} from "./cart";
import initModulesInScope from "@elements/init-modules-in-scope";
import formDataEntries from 'form-data-entries';

let pendingRequest = null;

export function initInScope ($scope) {
    $scope.find('.js-data-box').each(function () {
        let $container = $(this),
            $form = $container.find('.js-data-box__form');

        //switch to edit mode
        $container.find('.js-data-box__edit').on('click', function (evt) {
            if ($container.hasClass('data-box--preview')) {
                $container.removeClass('data-box--preview');
            }
        });

        $form.on('change', function () {
            $container.find('.js-data-box__form__group__input').each(function () {
                let $group = $(this).closest('.js-data-box__form__group');

                if ($(this).is(':checked')) {
                    $group.removeClass('is-inactive');
                    $group.find('.form-control').prop('disabled', false);

                    // if ($group.hasClass('js-data-box__form__group--required')) {
                    //     $group.find('.form-control, .js-required-group__input').prop('required', true);
                    // }
                } else {
                    $group.addClass('is-inactive');
                    $group.find('.form-control').prop('disabled', true);

                    if ($group.hasClass('js-data-box__form__group--required')) {
                        $group.find('.form-control, .js-required-group__input').prop('required', false);
                    }
                }
            });

            let isValid = true;
            $container.find('input[required]').each(function () {
                if ($(this).val().length < 1) {
                    isValid = false;
                    return false;
                }
            });

            if (isValid === true) {
                $container.find('.js-data-box__submit').prop('disabled', false)
            } else {
                $container.find('.js-data-box__submit').prop('disabled', true)
            }
        });

        $form.find('.js-data-box__form__group').on('click', function () {
            if ($(this).hasClass('is-inactive')) {
                let $selectInput = $(this).find('.js-data-box__form__group__input');

                if (!$selectInput.is(':checked')) {
                    $selectInput.prop('checked', true);
                    if ($(this).hasClass('js-data-box__form__group--required')) {
                        $(this).find('.form-control, .js-required-group__input').prop('required', true);
                    }
                    $form.trigger('change')
                }
            }
        });

        $form.find('.js-data-box__form__prefill').on('change', function () {
            loadContent($container, $(this).closest('form'));
        });

        $form.on('submit', function (evt) {
            evt.preventDefault();

            loadContent($container, $(this));
        });

        if ($form.data('submit-on-change')) {
            $form.on('change', function (evt) {
                evt.preventDefault();

                loadContent($container, $(this));
            });
        }
    });
}

function loadContent($container, $form) {
    if(pendingRequest){
        pendingRequest.abort();
        pendingRequest = null;
    }

    let params = new FormData($form[0]);

    pendingRequest = fetch($form.prop('action'), {
        method: 'POST',
        body: params,
        headers: {}
    });

    $container.addClass('is-loading');
    $container.find('.js-data-box__loading').attr('hidden', false);

    pendingRequest.then((res) => {
        return res.clone().json()
    }).then((res) => {
        if (res.success) {
            console.log(res.html)
            $container.html(res.html);
            initModulesInScope($container);

            getCartInfo();
        }

        pendingRequest = null;
        $container.find('.js-data-box__loading').attr('hidden', true)
    }).catch((error) => {
        if (error.name !== 'AbortError') {
            /*Do error stuff*/
            console.error(error);
            pendingRequest = null;
            $container.find('.js-data-box__loading').attr('hidden', true)
        }
    });
}

function getFormDataEntries(forms) {
    let formDataArray = [];

    forms.map(form => {
        for(let pair of formDataEntries(form)) {
            formDataArray.push(pair);
        }
    });

    return formDataArray;
}