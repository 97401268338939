"use strict";

export function initInScope ($scope) {
    $scope.find('.js-update-selection').on('change', function () {
        let $this = $(this);

        if ($this.is(':checked')) {
            $this.closest('form').prop('action', $this.data('target-action'))
        }

        if ($this.data('target')) {
            $($this.data('target')).val($this.data('value'));
        }
    });
}
