"use strict";

export function initInScope ($scope) {
    if (matchMedia('(min-width: 768px)').matches) {
        return;
    }

    let $centerTab = $scope.find('.js-scroll-tabs__item');

    $centerTab.each(function () {
        let $this = $(this);

        if ($this.hasClass('active')) {
            calcCenter($this.closest('.nav-item'))
        }

        if ($this.data('toggle')) {
            $this.on('show.bs.tab', function () {
                calcCenter($this.closest('.nav-item'))
            });
        }
    });



    function calcCenter($this) {
        if($this && $this.length) {
            let childPos = $this.offset().left,
                parentScroll = $this.closest('.js-scroll-tabs').scrollLeft(),
                offset = childPos + parentScroll - window.outerWidth/2 + $this.width()/2;

            try {
                $this.closest('.js-scroll-tabs')[0].scroll({
                    left: offset,
                    behavior: 'smooth'
                });
            } catch (e) {
                $this.closest('.js-scroll-tabs').scrollTo(offset, 0)
            }
        }
    }

}