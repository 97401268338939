"use strict";

export function initInScope ($scope) {
    let $checkbox = $scope.find('.js-collapse-checkbox');

    $checkbox.each(function(){
        checkboxToggle($(this));
    })

    $checkbox.on('change', function () {
        checkboxToggle($(this));
    });
}

function checkboxToggle(checkbox){
    let $target = $(checkbox.data('target')),
        required = checkbox.data('collapse-required');

    if (checkbox.is(':checked')) {
        $target.collapse('show');

        if (required) {
            $target.find('input, .form-control').prop('required', true);
        }
    } else {
        $target.collapse('hide');

        if (required) {
            $target.find('input, .form-control').prop('required', false);
        }
    }
}