"use strict";

let body = $('body'),
    lastScrollPosition = 0,
    nav;

export function init() {
    nav = $('.js-affix-nav');
    if (window.matchMedia('(min-width: 768px)').matches && nav ) {
        $(window).scroll(function () {
            lastScrollPosition = $(window).scrollTop();

            if (lastScrollPosition > nav.outerHeight()) {
                setAffix(nav)
            } else {
                unsetAffix(nav)
            }

        })
    }
}


export function setAffix(nav) {
    nav.addClass('is-affix');
    body.addClass('nav-affix');
    body.removeClass('nav-not-affix');
    nav.removeClass('not-is-affix');
}

export function unsetAffix(nav) {
    nav.removeClass('is-affix');
    body.removeClass('nav-affix');
    body.addClass('nav-not-affix');
    nav.addClass('not-is-affix');
}

