"use strict";

export function initInScope ($scope) {
    let $address = $scope.find('.js-address');
    let $billSelect = $address.find('.js-address__bill');
    let $deliverySelect = $address.find('.js-address__delivery');
    let $checkbox = $address.find('.js-address__checkbox');
    let deliveryOptions = [...$deliverySelect[0].options];
    let deliveryCountries = [];
    console.log($checkbox);

    deliveryOptions.map(option => {
        deliveryCountries.push(option.value);
    });

    $billSelect.on('change', function(){

        if(!deliveryCountries.includes($(this).val())){
            $checkbox.prop('checked', true);
            $checkbox.trigger('change');

            let hiddenInput = document.createElement('input');
            $(hiddenInput).attr('type', 'hidden');
            $(hiddenInput).attr('name', $checkbox.attr('name'));
            $(hiddenInput).attr('value', $checkbox.val());
            $(hiddenInput).addClass('js-address__hidden-input');

            $(hiddenInput).insertAfter($checkbox);
            $checkbox.prop('disabled', true);
        }else{
            if($checkbox.is(':disabled')){
                $checkbox.prop('disabled', false);
                if($('.js-address__hidden-input')){
                    $('.js-address__hidden-input').remove();
                }
            }
        }
        
    });
}
