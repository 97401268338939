"use strict";

export function initInScope($scope) {

    //find number spinner with class js-dependant-number-spinner
    let $dependantNumberSpinners = $scope.find('.js-dependant-number-spinner');

    $dependantNumberSpinners.each((index, element) => {
        let $dependentNumSpinner = $(element);
        let $dependantToId = $dependentNumSpinner.data("is-dependant-to");
        let $activatedFrom = $dependentNumSpinner.data("activated-from-count")
        let $dependantToInput = $scope.find('#' + $dependantToId);
        let $btUp = $dependentNumSpinner.find('.js-number-spinner__up');
        let $btDown = $dependentNumSpinner.find('.js-number-spinner__down');
        let $input = $dependentNumSpinner.find('.js-number-spinner__input')

        disableNumSpinner($btUp, $btDown, $input);

        $dependantToInput.on('change', function test(evt) {
            let $element = $(this);

            if($element.val() >= $activatedFrom ){
                console.log('activate')
                enableNumSpinner($btUp, $btDown, $input)
            } else {
                console.log('deactivate')
                disableNumSpinner($btUp, $btDown, $input)
            }
        })
    })

    function enableNumSpinner($btUp, $btDown, $input){
        //activate number spinner
        $btUp.attr('disabled', null);
        $btDown.attr('disabled', null);
        $input.attr('disabled', null);
    }

    function disableNumSpinner($btUp, $btDown, $input){
        //deactivate number spinner
        $btUp.attr('disabled', 'disabled');
        $btDown.attr('disabled', 'disabled');
        $input.attr('disabled', 'disabled');
        $input.val(0);
    }


}

