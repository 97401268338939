"use strict";
import {getPrefixedDataSet} from "@elements/data-set-utils";

export function initInScope ($scope) {
    $scope.find('.js-targeting-form').on('submit', function (evt) {

        let $this = $(this);
        evt.preventDefault();

        let checkedOption = $(this).find('.js-targeting-form__option:checked'),
            data = getPrefixedDataSet('targeting', checkedOption);
            let trackingEvent = data.tracking;

            if (Cookiebot.consent["marketing"]) {
                dataLayer.push({
                    'event': trackingEvent
                });
            }
        if (data.detail) {
            window.open(data.detail, '_blank');
            $this.closest('.js-targeting-modal').modal('hide');
        } else if (data.allow) {
            $this.closest('.js-targeting-modal').modal('hide');
            document.cookie = 'pimcore_targeting_disabled=0; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        } else if (data.disable) {
            let expires = data.disable === true ? '' : 'expires='+ data.disable;
            document.cookie = 'pimcore_targeting_disabled=1;' + expires;
            location.reload();
        }
    });
}