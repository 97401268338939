"use strict";

const defaultOptions = {
    debug: false
};

export function init(options = defaultOptions) {
    let form = $('.js-topcard-newsletter-tracking');
    let input = form.find('.js-topcard-newsletter-tracking__input');
    let trackingValue = '';


    if (form) {
        form.on('change', function () {
            trackingValue = input.find('option:selected').data('event-tracking');
        });

        form.on('submit', function () {
            window.dataLayer.push({
                'event': 'view_newsletter',
                'region': trackingValue,
            });

            if (options.debug) {
                console.log('dataLayer: ', window.dataLayer);
            }
        });
    }
}
