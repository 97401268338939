import debounce from 'debounce';

const defaultOptions = {
    mediaQuery: null
};

const defaultSelectors = {
    base: '.js-affix',
    placeholder: '.js-affix__placeholder',
    container: '.js-affix__container'
};

export function createInitInScope(options = defaultOptions, selectors = defaultSelectors) {
    console.log('is affix');
    let isRunning = false;
    let elements = [];
    let currentElements = []; // all elements except for elements that are taller than the viewport
    let matchesMediaQuery = options.mediaQuery ? matchMedia(options.mediaQuery).matches: true;

    options = {
        ...defaultOptions,
        ...options
    };

    if (options.mediaQuery) {
        // Use addListener instead of addEventListener because of IE
        matchMedia(options.mediaQuery).addListener(({matches}) => {
            matchesMediaQuery = matches;

            if (matches) {
                if (isRunning) {
                    requestAnimationFrame(interval)
                }
            } else {
                elements.forEach(resetElement);
            }
        });
    }

    $(window).on('resize', debounce(() => {
        currentElements = elements.filter(isElementTooBig);
        elements.forEach(resetElement);

        if (matchesMediaQuery) {
            elements.forEach(obj => {
                let elementRect =  obj.element.getBoundingClientRect();
                let placeholderRect =  obj.placeholder.getBoundingClientRect();
                let containerRect = obj.container.getBoundingClientRect();


                if (isAffixTopReached(containerRect, elementRect, placeholderRect, obj.offset, obj.isCentered, obj.isBottom)) {
                    if (isContainerBottomReached(containerRect, elementRect, placeholderRect, obj.offset, obj.isCentered, obj.isBottom)) {
                        setAffixBottom(obj);
                    } else {
                        setAffix(obj);
                    }

                    obj.isAffix = true;
                }
            });
        }

        lastScrollPosition = Number.NEGATIVE_INFINITY;
        interval();
    }, 200));

    let lastScrollPosition = 0;
    function interval() {
        if (!matchesMediaQuery) {
            return;
        }

        if (lastScrollPosition === window.pageYOffset) {
            requestAnimationFrame(interval);
            return;
        }

        lastScrollPosition = window.pageYOffset;

        currentElements.forEach(function (obj) {
            let elementRect =  obj.element.getBoundingClientRect();
            let placeholderRect  =  obj.placeholder.getBoundingClientRect();
            let containerRect = obj.container.getBoundingClientRect();

            if (obj.isAffix) {
                if (isContainerBottomReached(containerRect, elementRect, placeholderRect, obj.offset, obj.isCentered, obj.isBottom)) {
                    setAffixBottom(obj);
                } if (!isAffixTopReached(containerRect, elementRect, placeholderRect, obj.offset, obj.isCentered, obj.isBottom)){
                    resetElement(obj);
                }
            } else {
                if (isAffixTopReached(containerRect, elementRect, placeholderRect, obj.offset, obj.isCentered, obj.isBottom) && !isContainerBottomReached(containerRect, elementRect, placeholderRect, obj.offset, obj.isCentered, obj.isBottom)) {
                    setAffix(obj);

                    obj.isAffix = true;
                }
            }
        });

        requestAnimationFrame(interval);
    }

    function setAffixStates() {
        currentElements.forEach(function (obj) {
            let elementRect = obj.element.getBoundingClientRect();
            let placeholderRect = obj.placeholder.getBoundingClientRect();
            let containerRect = obj.container.getBoundingClientRect();

            if (isContainerBottomReached(containerRect, elementRect, placeholderRect, obj.offset, obj.isCentered, obj.isBottom)) {
                setAffixBottom(obj);
                obj.isAffix = false;
            } else if (isAffixTopReached(containerRect, elementRect, placeholderRect, obj.offset, obj.isCentered, obj.isBottom)) {
                setAffix(obj);
                obj.isAffix = true;
            } else {
                resetElement(obj);
                obj.isAffix = false;
            }
        });
    }

    return function initInScope($scope) {
        let $newElements = $scope.find(selectors.base);

        if (!$newElements.length) {
            return;
        }

        elements = elements.concat($newElements.toArray().map(function (element) {
            let $element = $(element);
            let $placeholder = $element.closest(selectors.placeholder);
            let $container = $element.closest(selectors.container);

            $element.css('transform', 'translateZ(0)');

            if (!$container || !$container.length) {
                console.warn('Could not find parent ".js-affix__container" for element ', element);
                return null;
            }

            if (!$placeholder || !$placeholder.length) {
                console.warn('Could not find parent ".js-affix__placeholder" for element ', element);
                return null;
            }

            if ($placeholder && $placeholder.length) {
                $placeholder.css('min-height', element.getBoundingClientRect().height);
            }

            return {
                element: element,
                placeholder: $placeholder[0],
                container: $container[0],
                isAffix: false,
                isCentered: $element.hasClass('js-affix--centered'),
                isBottom: $element.hasClass('js-affix--bottom'),
                offset: +$element.data('affix-offset') || 0
            }
        })
            .filter(x => !!x));

        // check if elements are in dom (so IE doesn't throw an error)
        elements = elements.filter(el => isElementInDom(el.element));

        currentElements = elements.filter(isElementTooBig);

        lastScrollPosition = 0;
        setAffixStates();
        if (!isRunning) {
            isRunning = true;
            requestAnimationFrame(interval);
        }
    }
}

export const initInScope = createInitInScope();

function resetElement(obj) {
    $(obj.element).removeClass('is-affix').css({
        position: '',
        top: '',
        left: '',
        width: '',
        transform: 'translateZ(0)',
    });

    if (obj.placeholder) {
        $(obj.placeholder).css('min-height', obj.element.getBoundingClientRect().height);
    }

    obj.isAffix = false;
}

function setAffix(obj) {
    let placeholderRect =  obj.placeholder.getBoundingClientRect();

    $(obj.element).addClass('is-affix').css({
        position: 'fixed',
        top: obj.offset,
        left: placeholderRect.left,
        width: placeholderRect.width,
        ...obj.isCentered ? {
            top: `calc(50% + ${obj.offset}px)`,
            transform: 'translateY(-50%)'
        } : null,
        ...obj.isBottom ? {
            top: `calc(100% - ${obj.offset}px)`,
            transform: 'translateY(-100%)'
        } : null
    });

    obj.isAffix = true;
}

function setAffixBottom(obj) {
    let placeholderRect =  obj.placeholder.getBoundingClientRect();
    let elementRect =  obj.element.getBoundingClientRect();
    let containerRect = obj.container.getBoundingClientRect();

    $(obj.element).removeClass('is-affix').css({
        position: 'relative',
        top: containerRect.height - (placeholderRect.top - containerRect.top) - elementRect.height,
        ...obj.isBottom ? {
            top: 0,
        } : null,
        left: '',
        transform: 'translateZ(0)',
    });

    obj.isAffix = false;
}

function isElementTooBig({element, offset}) {
    return element.getBoundingClientRect().height + offset <= window.innerHeight
}

function isContainerBottomReached(containerRect, elementRect, placeholderRect, offset, isCentered = false, isBottom = false) {
    if(isCentered) {
        return containerRect.top + containerRect.height - offset - elementRect.height - (window.innerHeight / 2) + (elementRect.height / 2) <= 0
    } else if (isBottom) {
        return containerRect.bottom + offset - (window.innerHeight) + (elementRect.height) <= 0
    } else {
        return containerRect.top + containerRect.height - offset - elementRect.height <= 0;
    }
}

function isAffixTopReached(containerRect, elementRect, placeholderRect, offset, isCentered = false, isBottom = false) {
    if(isCentered) {
        return placeholderRect.top - offset - (window.innerHeight / 2) + (elementRect.height / 2) <= 0
    } else if (isBottom) {
        return placeholderRect.top - offset - (window.innerHeight) + (elementRect.height) >= 0
    } else {
        return placeholderRect.top - offset <= 0;
    }
}

function isElementInDom(el) {
    if (typeof $ === "function" && el instanceof $) {
        el = el[0];
    }

    return document.body.contains(el);
}