"use strict";
import initModulesInScope from "@elements/init-modules-in-scope";

export function initInScope ($scope) {
    $scope.find('.js-top-card__sub-group').each(function () {
        let $group = $(this),
            startCount = $group.data('card-start-count') || 1,
            $addButton = $group.find('.js-top-card__add');

        $addButton.on('click', function () {
            renderPerson($group, startCount);
            startCount = startCount + 1;
        });
    });

    $scope.find('.js-top-card__add').one('click', function () {
        if ($(this).closest('.js-top-card__sub-group').length > 0) {
            return;
        }

        let count = $(this).closest('.js-top-card__group').find('.js-top-card__person').length + 1;
        renderPerson($(this).closest('.js-top-card__group'), count);
    });

    $scope.find('.js-top-card__person-remove').on('click', function (evt) {
        $(this).closest('.js-top-card__person').remove();
    });

    $scope.find('.js-top-card__keycard').on('change', function () {
        let $container = $(this);
        let $input = $container.find('.js-top-card__keycard-input');
        let $image = $container.find('.js-top-card__keycard-image');

        if ($input.is(':checked')) {
            $image.attr('hidden', false);
            $image.find('input').prop('required', true);
        } else {
            $image.attr('hidden', true);
            $image.find('input').prop('required', false);
        }
    });

    $scope.find('.js-top-card__type').on('change', function () {
        let $container = $(this).closest('.js-top-card');

        let cardType = $(this).find("option:selected").data('card-type');

        if (cardType === 'family') {
            $container.find('.js-top-card__group[data-card-type]').attr('hidden', true);
            $container.find('.js-top-card__group[data-card-type]').prop('required', false);
        } else {
            $container.find('.js-top-card__group[data-card-type="family"]').attr('hidden', true);
            $container.find('.js-top-card__group[data-card-type="family"]').prop('required', false);
        }

        $container.find('.js-top-card__group[data-card-type="'+ cardType +'"]').attr('hidden', false);
        $container.find('.js-top-card__group[data-card-type="'+ cardType +'"]').prop('required', true);
    });
}

function renderPerson($group, count) {
    let template = $($group.find('.js-top-card__template').html());
    let clone = template.clone();

    let $title = clone.find('.js-top-card__group-title');
    $title.text($title.text().replace("[count]", ""+ count +""));

    clone.find('input').each(function (index, input) {
        let newName = $(input).prop('name').replace("[count]", "["+ count +"]");
        let newId = $(input).prop('id').replace("[count]", "["+ count +"]");
        $(input).prop('name', newName);
        $(input).prop('id', newId);
        $(input).closest('.form-group').find('label').prop('for', newName);
    });

    $group.find('.js-top-card__content').append(clone);
    initModulesInScope($group);
}