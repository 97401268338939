"use strict";

import {isValid, loadParsley, isParsleyForm} from "@elements/parsley-bootstrap-validation";
import {getPrefixedDataSet} from "@elements/data-set-utils";

const defaultSelectors = {
    form: '.js-reload-form',
    optionalButton: '.js-reload-form__btn',
    loading: '.js-reload-form__loading'
};

export function createReloadForm(selectors = defaultSelectors) {
    return function ($scope) {
        let $reloadForm = $scope.find(selectors.form);

        $reloadForm.on('submit', function () {
            let $form = $(this);

            if (isParsleyForm($form)) {
                loadParsley().then(function () {
                    if (isValid($form)) {
                        handleFormSubmit($form, selectors);
                    }
                });
            } else {
                handleFormSubmit($form, selectors);
            }
        });
    }
}

function handleFormSubmit($form, selectors) {
    $form.find(selectors.loading).attr('hidden', null);

    let dataSet = getPrefixedDataSet('reload-form', $form);

    if (dataSet.disableButton !== false) {
        $form.find('button[type="submit"], button:not([type])').prop('disabled', true);
    }

    $(selectors.optionalButton).prop('disabled', true);
}

export const initInScope = createReloadForm(defaultSelectors);


