"use strict";

export function init() {
    let redirectForm = $('.js-select-redirect');
    if (redirectForm) {
        let select = redirectForm.find('.js-select-redirect__select');

        select.on('change', function (evt) {
            let url = $(this).val();
            redirectForm.attr('action', url);
        });
    }
}