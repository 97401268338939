"use strict";

import loadJQueryUi from '@elements/load-jquery-ui';
import {dateToISOString, ISOStringToDate, localDateToUTCDate, UTCDateToLocalDate} from "@elements/date-utils";
import {getPrefixedDataSet} from "@elements/data-set-utils";
import fetch from '@elements/fetch';
import initModulesInScope from "@elements/init-modules-in-scope";

function addKeyboardSupport($datepicker, $altField) {
    let newDate = localDateToUTCDate($datepicker.datepicker('getDate'));

    $datepicker.on('keydown', function (evt) {
        if(newDate == null) {
            $datepicker.datepicker('setDate', new Date());
            newDate = $datepicker.datepicker('getDate');
        }

        switch (evt.key) {
            case 'ArrowLeft':
                newDate.setDate(newDate.getDate() - 1);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'ArrowUp':
                newDate.setDate(newDate.getDate() - 7);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'ArrowRight':
                newDate.setDate(newDate.getDate() + 1);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'ArrowDown':
                newDate.setDate(newDate.getDate() + 7);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'PageUp':
                newDate.setDate(newDate.getDate() - 30);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'PageDown':
                newDate.setDate(newDate.getDate() + 30);
                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
            case 'Enter':
                newDate.setDate(newDate.getDate());
                $datepicker.datepicker('setDate', newDate);
                $altField.val(dateToISOString(localDateToUTCDate(newDate))).trigger('change');
                $datepicker.datepicker('hide');

                evt.preventDefault();
                evt.stopImmediatePropagation();
                break;
        }

        if (newDate.getTime() !== $datepicker.datepicker('getDate').getTime()) {
            $datepicker.datepicker('setDate', newDate);
        }
    });
}

// returns UTC Date
export function getDate($datepicker) {
    return ISOStringToDate($datepicker.find('.js-datepicker__alt-field').val());
}

// date: UTC DATE
export function setDate($datepicker, date) {
    loadJQueryUi().then(function () {
        $datepicker.find('.js-datepicker__alt-field').val(dateToISOString(date));
        $datepicker.find('.js-datepicker__input').datepicker('setDate', UTCDateToLocalDate(date));
    });

}

// date: UTC DATE
export function setMinDate($datepicker, date) {
    loadJQueryUi().then(function () {
        // todo do not use get time but the alt field
        if ($datepicker.find('.js-datepicker__input').val() && $datepicker.find('.js-datepicker__input').datepicker('getDate').getTime() < date.getTime()) {
            $datepicker.find('.js-datepicker__alt-field').val(dateToISOString(date));
            $datepicker.find('.js-datepicker__input').datepicker('setDate', UTCDateToLocalDate(date));
        }

        $datepicker.find('.js-datepicker__input')
            .datepicker('option', 'minDate', UTCDateToLocalDate(date));
    });
}

// date: UTC DATE
export function setMaxDate($datepicker, date) {
    loadJQueryUi().then(function () {
        // todo do not use get time but the alt field
        if ($datepicker.find('.js-datepicker__input').val() && $datepicker.find('.js-datepicker__input').datepicker('getDate').getTime() > date.getTime()) {
            $datepicker.find('.js-datepicker__alt-field').val(dateToISOString(date));
            $datepicker.find('.js-datepicker__input').datepicker('setDate', UTCDateToLocalDate(date));
        }

        $datepicker.find('.js-datepicker__input')
            .datepicker('option', 'maxDate', UTCDateToLocalDate(date));
    });
}

export function createInitInScope(selectors, defaultOptions) {
    return function ($scope) {
        let $datepickerContainers = $scope.find(selectors.base);

        loadJQueryUi().then(function () {
            $datepickerContainers.each(function () {
                let $container = $(this);
                let $datepicker = $container.find(selectors.input);
                let $altField = $container.find(selectors.altField);
                let $loadingOverlay = $container.find('.js-datepicker__loading-overlay');

                let availabilities = false;

                let selectedDate;
                let defaultDate = UTCDateToLocalDate(new Date());
                let selectedDays = parseInt($container.attr('data-datepicker-selected-days')) - 1;
                let fromDate = defaultDate;
                let toDate = new Date().setDate(fromDate.getDate() + selectedDays);
                let dataMinDate = $container.data('datepicker-min-date');

                if(dataMinDate.length > 0){
                    if(ISOStringToDate(dataMinDate).getTime() > fromDate.getTime()){
                        fromDate = ISOStringToDate(dataMinDate);
                    }
                }

                let options = {
                    numberOfMonths: 1,
                    minDate: UTCDateToLocalDate(new Date()),
                    nextText: '<span class="icon icon-arrow"></span>',
                    prevText: '<span class="icon icon-arrow"></span>',
                    firstDay: 1,
                    showAnim: 'show', // other animations (like fadeIn) do not work with jquery.slim,
                    onSelect: function(dateString, inst) {
                        let selectedDate = new Date(Date.UTC(inst.selectedYear, inst.selectedMonth, inst.selectedDay));
                        fromDate = selectedDate;
                        setDate($container, fromDate);
                    },
                    beforeShowDay: function (date) {
                        let cssClasses = [];

                        if($container.data('datepicker-availability').length > 0) {
                            for (let i = 0; i < availabilities.length; i++) {
                                if (dateToISOString(new Date(availabilities[i].date)) === dateToISOString(date)) {
                                    cssClasses.push(availabilities[i].state);
                                }
                            }
                        }

                        // add date range
                        toDate = new Date(fromDate).setDate(fromDate.getDate() + selectedDays);

                        if (date.getTime() >= fromDate.getTime() && date.getTime() <= toDate) {
                            cssClasses.push('ui-state-highlight');
                        }

                        return [true, cssClasses.join(' ')];
                    },
                    onChangeMonthYear: function (year, month) {
                        getAvailability($container, {year, month})
                    },
                    ...defaultOptions,
                    ...transformDataOptions(getPrefixedDataSet('datepicker', $container))
                };

                if ($altField.val()) {
                    options.defaultDate = ISOStringToDate($altField.val());
                }

                addKeyboardSupport($datepicker, $altField);
                $datepicker.datepicker(options);

                if ($container.data('datepicker-availability')) {
                    if($container.data('datepicker-min-date')){
                        let minDate = $container.data('datepicker-min-date');
                        let year = UTCDateToLocalDate(ISOStringToDate(minDate)).getFullYear();
                        let month = UTCDateToLocalDate(ISOStringToDate(minDate)).getMonth() + 1;
                        getAvailability($container, {year, month})
                    }
                    else{
                        let year = UTCDateToLocalDate(new Date()).getFullYear();
                        let month = UTCDateToLocalDate(new Date()).getMonth() + 1;
                        getAvailability($container, {year, month})
                    }
                }

                function getAvailability($container, date) {
                    let availabilityRequest;
                    $loadingOverlay.removeAttr('hidden');

                    availabilityRequest = fetch($container.data('datepicker-availability'), {
                        method: 'GET',
                        headers: {
                            'X-Requested-With' : 'XMLHttpRequest',
                            'Content-Type': 'application/json'
                        },
                        body: date
                    });

                    availabilityRequest.then((res) => {
                        return res.clone().json()
                    }).then((res) => {
                        if (res.success) {
                            availabilities = res.data;
                            $datepicker.datepicker('refresh');
                            $loadingOverlay.attr('hidden', true);
                        }
                    }).catch((error) => {
                        if (error.name !== 'AbortError') {
                            /*Do error stuff*/
                            console.error(error);
                        }
                    });
                }

                if ($altField.val()) {
                    setDate($container, ISOStringToDate($altField.val()));
                }

                $altField.on('change', () => {
                    setDate($container, ISOStringToDate($altField.val()));
                });
            });
        });
    }
}

export const initInScope = createInitInScope({
    base: '.js-datepicker-inline',
    input: '.js-datepicker__input',
    altField: '.js-datepicker__alt-field'
});

function transformDataOptions(options) {
    options = {...options};

    if (options.minDate && typeof options.minDate === 'string') {
        options.minDate = UTCDateToLocalDate(ISOStringToDate(options.minDate));
    }

    if (options.maxDate && typeof options.maxDate === 'string') {
        options.maxDate = UTCDateToLocalDate(ISOStringToDate(options.maxDate));
    }

    return options;
}