"use strict";

export function initInScope($scope) {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        console.log("enumerateDevices() not supported.");
        return;
    }

    import('qr-scanner').then((QrScanner) => {
        QrScanner = QrScanner.default;
        QrScanner.WORKER_PATH = '/static/js/libs/qr-scanner-worker.min.js';

        QrScanner.hasCamera().then(function(deviceHasCamera){

            if(deviceHasCamera) {

                let $qrScanner = $scope.find('.js-qr-scanner');
                $qrScanner.each(function() {
                    let scannerContainer = this;
                    let $scannerContainer = $(this);
                    let video = scannerContainer.getElementsByClassName('js-qr-scanner__video')[0];

                    let $videoQrResultInput = $scannerContainer.find('.js-qr-scanner__result-input');
                    let $videoQrResult = $scannerContainer.find('.js-qr-scanner__result');
                    let $preview = $scannerContainer.find('.js-qr-scanner__preview');

                    let scanner;
                    let $button = $scannerContainer.find('.js-qr-scanner__btn');

                    try {
                        $button.attr('hidden', false);
                        scanner = new QrScanner(video, result => setQrScanResult($videoQrResult, $videoQrResultInput, $preview, result, scanner));

                        $button.on('click', function() {
                            video.play();
                            scanner.start();
                            $preview.attr('hidden', false);
                        });

                    } catch(error) {
                        $button.attr('hidden', true);
                    }


                });
            } else {
                $scope.find('.js-qr-scanner__btn').attr('hidden', true);
                console.warn('QR-Scanner: No Camera detected. QR Scanner will not be available for keycard scanning.');
            }
        });
    });

    function setQrScanResult(resultContainer, resultInput, preview, result, scanner) {
        let newKeycardNumber = result.split('?rfid=').pop();

        preview.attr('hidden', true);
        resultInput.val('');
        resultInput.attr('maxlength','29');
        resultInput.val(newKeycardNumber);
        resultInput.trigger('change');
        scanner.stop();
        scanner = null;
    }
}