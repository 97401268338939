"use strict";

import Inputmask from "inputmask";

export function initInScope ($scope) {
    let $inputs = $scope.find('.js-mask-input');

    $inputs.each(function () {
        Inputmask().mask($(this));
    });
}